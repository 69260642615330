import React from "react";
import Home from "./pages/Home";

class App extends React.Component {
  render() {
    return <Home />;
  }
}

export default App;
