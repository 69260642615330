import React from 'react';
import ReactDOM from 'react-dom';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import axios from 'axios';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import App from './App.js';
import IndividualPoster from './pages/IndividualPoster';
import TeamPoster from './pages/TeamPoster';
import PhotoDay from './pages/PhotoDay';
import MemoryMate from './pages/MemoryMate';
import Notfound from './pages/error/Notfound';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';
import './fontawesome-free-5.14.0-web/css/all.css';
import './index.scss';
import './editor.scss';
import './canvas.scss';
import './layout.scss';

import * as serviceWorker from './serviceWorker';
import { composeWithDevTools } from 'redux-devtools-extension';
import Editor from './pages/Editor';
import rootReducer from './Reducers/index.tsx';

axios.interceptors.request.use((x) => {
  x.meta = x.meta || {};
  x.meta.requestStartedAt = new Date().getTime();
  return x;
});

axios.interceptors.response.use(
  (x) => {
    console.log(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`);
    return x;
  },
  // Handle 4xx & 5xx responses
  (x) => {
    console.log(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`);
    throw x;
  }
);

const store = createStore(rootReducer, composeWithDevTools());

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || '9dcfcad221e64c8985bbcc6f97c36170',
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT || 'production',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

const DisplayForError = (error) => (
  <div className='component-display'>
    <div>{error || 'Error'}</div>
  </div>
);

const routing = (
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary fallbackUI={DisplayForError}>
      <Provider store={store}>
        <Router basename=''>
          <Switch>
            <Route exact path='/' component={App} />
            <Route path='/team-poster' component={TeamPoster} />
            <Route path='/individual-poster' component={IndividualPoster} />
            <Route path='/photo-day' component={PhotoDay} />
            <Route path='/memory-mate' component={MemoryMate} />
            <Route path='/editor' component={Editor} />
            <Route component={Notfound} />
          </Switch>
        </Router>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
