import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import { castArray } from 'lodash';

class S3Uploader {
  constructor({ region, bucket, accessKeyId, secretAccessKey }) {
    this._bucket = bucket;
    this._client = new S3Client({
      useAccelerateEndpoint: true,
      region,
      credentials: {
        accessKeyId,
        secretAccessKey,
      },
    });
  }

  upload = (files) => {
    return Promise.all(
      castArray(files).map((file) => {
        if (!file) {
          return Promise.resolve('');
        }

        const bucketParams = {
          Bucket: this._bucket,
          Key: `${uuidv4()}.${file.type.split('/').pop()}`,
          Body: file,
        };

        return this._client.send(new PutObjectCommand(bucketParams)).then(() => {
          return `https://${bucketParams.Bucket}.s3.amazonaws.com/${bucketParams.Key}`;
        });
      })
    ).then((urls) => {
      console.log('Uploaded to S3:', urls);
      return urls;
    });
  };
}

export default S3Uploader;
