const config = {
  POSTER_BUILDER_API_BASE_URL: process.env[`REACT_APP_POSTER_BUILDER_API_BASE_URL`],
  UTPE_PLAYER_API_URL: process.env[`REACT_APP_UTPE_PLAYER_API_URL`],
  BG_REMOVE_URL: process.env['REACT_APP_BG_REMOVE_URL'],
  S3_ACCESS_KEY_ID: process.env['REACT_APP_S3_ACCESS_KEY_ID'],
  SECRET_ACCESS_KEY: process.env['REACT_APP_SECRET_ACCESS_KEY'],
  UPLOADS_BUCKET: process.env['REACT_APP_UPLOADS_BUCKET'],
  UPLOADS_BUCKET_REGION: process.env['REACT_APP_UPLOADS_BUCKET_REGION'],
};

const hasMissingEnvVars = Object.values(config).filter((x) => (x || '').trim().length === 0).length > 0;

if (hasMissingEnvVars) {
  throw new Error('Missing configuration variables');
}

config.s3Config = {
  accessKeyId: config.S3_ACCESS_KEY_ID,
  secretAccessKey: config.SECRET_ACCESS_KEY,
  bucket: config.UPLOADS_BUCKET,
  region: config.UPLOADS_BUCKET_REGION,
};

export default config;
