import axios from 'axios';
import demoImage from '../assets/img/demo.js';
import envConfig from '../config';
import S3Uploader from './S3Uploader.js';

export const toDataURL = (url, forcePNG = false) => {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            let base64 = reader.result;
            if (forcePNG) {
              base64 = 'data:image/png;base64,' + base64.split('base64,')[1];
            }

            resolve(base64);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
};

const toRawImage = (url) => fetch(url).then((x) => x.blob());

// do Accept poster, and return unique_id
/**
 * posterType: integer indicating the poster type, 0: Individual poster, 1: Team poster, 2: Combo poster and many more
 *
 * @param {Object} acceptedFormData
 * @param {Number} posterType
 * @param {String} dataForApprove
 * @return Promise
 */

export const acceptPoster = async (acceptedFormData, posterType = 0, dataForApprove = '') => {
  const formData = new FormData();

  formData.append('approve', true);
  formData.append('posterType', posterType);
  formData.append('dataForApprove', dataForApprove);

  const { rawImages } = acceptedFormData;

  if (rawImages) {
    const rawImageS3Urls = await new S3Uploader(envConfig.s3Config).upload(rawImages);
    formData.append('rawImages', JSON.stringify(rawImageS3Urls));
    delete acceptedFormData.rawImages;
  }

  formData.append('settings', JSON.stringify(acceptedFormData));

  // API UPDATE
  return await axios.post(`${envConfig.POSTER_BUILDER_API_BASE_URL}/poster/approve`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

// fetch player data from the event system
export const fetchPlayerData = async (playerId) => {
  const response = await axios.get(`${envConfig.POSTER_BUILDER_API_BASE_URL}/player/${playerId}`);

  const { data } = response;

  if (data.statusCode !== 200) {
    throw new Error(`${data.type.error} on fetchPlayerData() : data.type.description`);
  }

  const playerData = data.data;

  for (const key in playerData) {
    if (playerData[key] === null || playerData[key] === undefined) {
      delete playerData[key];
    }
  }

  // download images and convert them to base64
  const base64Data = await Promise.all(playerData.images.map(({ link }) => (link ? toDataURL(link) : '')));
  base64Data.forEach((data, i) => (playerData.images[i].base64 = data));

  return playerData;
};

// fetch team data from the event system
export const fetchTeamData = async (teamId, eventId) => {
  const response = await axios.get(`${envConfig.POSTER_BUILDER_API_BASE_URL}/team/${teamId}/${eventId}`);

  const { data } = response;

  if (data.statusCode !== 200) {
    throw new Error(`${data.type.error} on fetchTeamData() : data.type.description`);
  }

  const teamData = data.data;

  // download images and convert them to base64
  const base64Data = await Promise.all(teamData.images.map(({ link }) => (link ? toDataURL(link) : '')));
  base64Data.forEach((data, i) => (teamData.images[i].base64 = data));

  return teamData;
};

// fetch poster data from internal API
export const fetchPosterData = async (posterId) => {
  const response = await axios.get(`${envConfig.POSTER_BUILDER_API_BASE_URL}/poster/${posterId}`);

  const { data } = response.data;

  if (response.data.statusCode !== 200) {
    throw new Error('Error on fetchPosterData(): '.data.type.description);
  }

  const rawImages = await Promise.all(data.raw_images.map((url) => (url ? toRawImage(url) : null)));
  const processedImages = await Promise.all(data.processed_images.map((url) => (url ? toDataURL(url) : demoImage)));
  const producedImage = await toDataURL(data.product);

  let posterData = {
    producedImage,
    images: rawImages,
    base64: processedImages,
    ...JSON.parse(data.settings),
  };

  if (data.logo_raw_link) {
    posterData.pickerImagePath = data.logo_raw_link;
  }

  return posterData;
};
